<template>
	<div class="product-container">
		<!-- <div class="product-box"></div> -->
		<h1>{{$t('basic.development')}}...</h1>
	</div>
</template>

<script>
export default {
  metaInfo: {
    title: 'emData 产品',
    meta:
			[
			  {
			    name: 'emData 产品',
			    content: 'emData 产品有哪些？'
			  },
			  {
			    name: '鹦鹉塔产品',
			    content: '鹦鹉塔 产品有哪些'
			  }
			],
    link:
			[
			  {
			    rel: 'emData 产品',
			    href: 'https://www.emdataer.com/product'
			  }
			]
  },
  name: 'product',
  data () {
    return {

    }
  }
}
</script>

<style scoped lang="less">
	.product-container{
		width: 100%;
		height: calc(100vh - 60px);
		padding: 50px;
		box-sizing: border-box;
		.product-box{
			width: 80%;
			height:calc(100% - 100px);
			margin: auto;
			// background-image: url(../../assets/officialwebsite/product.jpg);
			background-size:100% 100%;
			background-repeat:no-repeat;
		}
	}
</style>
